import React from 'react';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components';

import { Section } from '~/components/section';
import { ReactComponent as Illustration } from '~/static/svg/illustration-404.svg';
import { Container } from '~/theme';

const IllustrationWrapper = styled(Div100vh)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage = () => (
  <Container>
    <Section>
      <IllustrationWrapper>
        <Illustration />
      </IllustrationWrapper>
    </Section>
  </Container>
);

export default NotFoundPage;
